// ******************************
// Import scss files you need!!!
// ******************************

@import "../../styles/base";
@import "../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container-gear{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 20px 20px 20px;
	margin-top: 80px;
}
.gear-main-container,
.container-gear{
	.quantity{
		display: flex;
		flex-direction: column;

		&.search{
			.MuiInputLabel-shrink {
				transform: translate(0, -10px) scale(0.75) !important;
			}
			.MuiInputLabel-formControl{
				text-indent: 10px;
				transform: translate(0, 17px) scale(1);
			}
			input{
				height: 40px;
				font-size: 16px;
			}
		}
		.MuiInput-formControl{
			margin-top: 5px;
			background-color: transparent;

			&:hover{
				input{
					border-color: $secondary-color;
				}
			}

			&:after,
			&:hover:before{
				border: none;
			}
			&:before{
				border-bottom-color: transparent;
			}
		}
		input {
			text-align: left;
			text-indent: 10px;
			height: 28px;
			margin: 0;
			padding: 0 5px;
			border-radius: 5px;
			border: solid 1px lighten($border-color, 5%);
			background-color: transparent;
			font-weight: $font-semibold;
			font-family: $font-family;
			font-size: 16px;
		}
	}
}

.gear-main-container {
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-wrap: wrap;
	font-family: $font-family;
	font-weight: $font-semibold;
	font-size: 12px;

	.gear-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: space-between;
		margin: 10px 10px 20px 10px;
		flex: 1 1 140px;
		background: white;
		border: 1px solid $border-color;
		padding: 10px 5px;
		max-width: 200px;

		.upper {
			justify-content: normal;
			padding-bottom: 5px;

			.item-name {
				width: 80%;
				margin: auto;
			}
			.gear {
				position: relative;

				img {
					box-shadow: 0 0 4px 0 transparentize($title-color, .8);
				}
			}
		}
		.gear-quantity {
			width: 80%;
			display: flex;
		}
	}
}

.star {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 1;

	&:hover {
		color: $primary-color;
	}
}

.error {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	flex-direction: column;
	margin-top: calc(15% + 80px);
	color: $title-color;

	svg {
		font-size: 40px;
		margin-bottom: 20px;
	}
}