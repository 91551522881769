// ******************************
// ----- Config file -----
// ******************************

@import "base";
@import "./fonts/open-sans";

// A map of breakpoints.
$breakpoints: (
        xs: 768px,
        sm: 1024px,
        md: 1200px,
        lg: 1550px,
        xl: 1800px
);

//  -------- THEME --------

$primary-color: #0aa4ff;
$secondary-color: #343963;

$title-color: #151a30;
$label-color: #7d86a9;
$border-color: #d2dce6;
$border-container: #979797;

$bg-color: #f8fcff;
$row-color: #fff;

$btn-color: #dbe1ec;
$cbox-active: $primary-color;
$cbox-inactive: #5a5a5a;



//  -------- Sizes --------
$menu-width: 200px