// ******************************
// Import scss files you need!!!
// ******************************

@import "./styles/base";
@import "./styles/config";

// ******************************
// end of the imported scss files
// ******************************

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: $font-family;
  background: $bg-color;
  font-size: 14px;
}

a, a:visited button {
  color: white;
  text-decoration: none;

  &:hover{
    color: $primary-color;
  }
}
input {
  font: $font-semibold 16px $font-family;

  &:focus,
  &:active{
    color: $primary-color !important;
  }
}