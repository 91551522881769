// ******************************
// Import scss files you need!!!
// ******************************

@import "../../styles/base";
@import "../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.header-top {
	display: flex;
	flex: auto;
	flex-grow: 1;
	position: fixed;
	z-index: 100;
	font-family: $font-family;
	width: 100%;

	header {
		width: 100%;
		height: 60px;
		margin: 0 0 24px;
		background-image: radial-gradient(circle at 0 0, #484b5b, #2c2d35 69%);

		.MuiToolbar-gutters {
			justify-content: space-between;
			padding: 0 10px;
		}

		.title {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #edf5fc;
			flex-grow: 1;

			.logo {
				display: inline-flex;
				width: 46px;
				position: relative;
				height: 60px;
				margin-top: 5px;
				margin-right: 5px;
				@include contain-background('./img/logo.png');
			}
		}
		.right-aside{
			display: flex;
		}

		.menuBtn {
			flex-direction: column;
			color: $bg-color;
			font-size: 10px;

			span {
				display: flex;
				flex-direction: column;
			}

			svg {
				display: flex;
				font-size: 24px;
			}

			&:hover {
				color: $primary-color;
			}
		}
	}
}


@include respond-below(xs){
	.header-top{
		header{
			.MuiToolbar-gutters {
				padding: 0;
			}
			.title{
				padding-left: 15px;

				.txt{
					display: inline-flex;
					align-items: center;
					margin-top: 10px;
					height: 60px;
					font-size: 12px;
					max-width: 80px;
				}
			}
			.menuBtn{
				padding: 6px 3px;
				min-width: 50px;

				.txt{
					display: none;
				}
			}
		}
	}
}
