// ******************************
// Import scss files you need!!!
// ******************************

@import "../../styles/base";
@import "../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.sidebar {
	display: flex;
	flex-direction: column;
	position: fixed;
	right: 0;
	top: 0;
	width: $menu-width;
	height: 100vh;
	object-fit: contain;
	background: #fff;
	border-left: 1px solid transparentize($border-color, .2);
	z-index: 99;
	padding-top: 80px;
	box-sizing: border-box;
	transition: all .3s ease-in-out;

	&.collapsed {
		right: -#{$menu-width};
	}

	.wrapper{
		display: flex;
		flex-direction: column;
		overflow: auto;
		flex: auto;
	}
	.gear-option-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: -40px;
		top: 30%;
		margin-top: -15px;
		width: 40px;
		height: 40px;
		background: #fff;
		border: 1px solid transparentize($border-color, 0);
		border-right-width: 0;
		border-radius: 4px 0 0 4px;
		box-shadow: -6px 2px 8px -3px transparentize(black, .9);
		cursor: pointer;

		&:hover {
			.gear-option-icon {
				color: $primary-color;

				&.spin {
					animation: spin 2s linear infinite;
				}
			}
		}

		.gear-option-icon {
			font-size: 18px;
			color: $title-color;
		}
	}

	.title {
		display: flex;
		align-content: center;
		align-items: center;
		margin-bottom: 10px;
		width: 100%;
		background-color: transparentize($bg-color, .5);
		padding: 10px 0;

		.txt {
			text-align: center;
			width: 100%;
			font-size: 12px;
			font-family: $font-family;
			font-weight: $font-semibold;
			color: $title-color;
		}
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}


@include respond-below(sm) {
	.sidebar {
		width: 100%;
		z-index: 999;
		padding-top: 60px;

		&.collapsed{
			right: -100%;

			.gear-option-btn {
				left: -40px;
				top: 30%;
				right: auto;
			}
		}
		.gear-option{
			.input-energy{
				margin: 5px 15px;
			}
		}

		.gear-option-btn {
			left: auto;
			right: 10px;
			top: 30px;
			border: none;
			box-shadow: none;

			.gear-option-icon{
				font-size: 24px;
			}
		}
		.title{
			.txt{
				font-size: 14px;
			}
		}
		.gear-option{
			.MuiFormGroup-row{
				flex-direction: column;
				padding: 10px 15px;
				flex-wrap: nowrap;

				.input-energy{
					margin: 5px 10px;
				}
			}
		}
	}
}
