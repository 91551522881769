.container {
	display: flex;
	flex-direction: column;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

.gear-border {
	display: inline-block;
	max-width: 38px;
	max-height: 38px;
	padding: 0;
	border-width: 2px;
	border-style: solid;

	&.gear-tier-1 {
		border-color: #97d2d3;
		background: radial-gradient(#4391a3, #000 80%);
	}

	&.gear-tier-2 {
		border-color: #aff65b;
		background: radial-gradient(#4c9601, #000 80%);
	}

	&.gear-tier-4 {
		border-color: #51bcf6;
		background: radial-gradient(#004b65, #000 80%);
	}

	&.gear-tier-7 {
		border-color: #844df1;
		background: radial-gradient(#4700a7, #000 80%);
	}

	&.gear-tier-11 {
		border-color: #844df1;
		background: radial-gradient(#4700a7, #000 80%);
	}

	&.gear-tier-12 {
		border-color: #f1c752;
		background: radial-gradient(#997300, #000 80%);
	}
}