// ******************************
// Import scss files you need!!!
// ******************************

@import "../../styles/base";
@import "../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.gear-option {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px 0;
	width: 100%;
	margin: 0;
	box-shadow: none !important;

	.hide {
		display: none;
	}

	.show {
		display: flex;
	}

	.MuiFormControlLabel-root{
		display: flex;
		margin: 5px 10px;
	}
	.title{
		font-family: $font-family;
		font-size: 12px;
		font-weight: 600;
		color: $title-color;
		margin-bottom: 0;
		margin-left: 15px;
		background: transparent;
		padding: 15px 0 0 0;
	}
	.input-energy{
		display: inline-flex;
		margin: 5px auto;

		&:hover{
			input{
				border-color: $secondary-color;
			}
		}
		&:after,
		&:hover:before{
			border-width: 0;
		}
		&:before{
			border-bottom-color: transparent !important;
		}
	}
	input{
		text-align: left;
		text-indent: 10px;
		min-width: 100px;
		font-size: 16px;
		font-weight: $font-semibold;
		font-family: $font-family;
		height: 36px;
		margin: 0;
		padding: 0 5px;
		border-radius: 5px;
		border: solid 1px lighten($border-color, 5%);
		background-color: transparent;
	}
}