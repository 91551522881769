// ******************************
// Import scss files you need!!!
// ******************************

@import "../../styles/base";
@import "../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.home {
  display: flex;
  width: calc(100% - 4%);
  flex-direction: column;
  flex: auto;
  margin: 70px 2% 0;
  transition: all .3s ease-in-out;

  &.collapsed{
    width: calc(100% - (4% + #{$menu-width}));
  }
}
