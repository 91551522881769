//*****
//*
// Font family default Open Sans
//*
//*****
// Import Font from Google Font
//*****

@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);

// ******************************


$font-family: 'Open Sans', sans-serif !default;

$font-light: 300 !default;
$font-regular: 400 !default;
$font-semibold: 600 !default;
$font-bold: 700 !default;