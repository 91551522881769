// ******************************
// Import scss files you need!!!
// ******************************

@import "../base";
@import "../config";

// ******************************
// end of the imported scss files
// ******************************


.custom-cbox {
	display: flex;
	min-height: 50px;
	padding: 5px 0;
	flex: auto;
	border: 1px solid $border-color;
	border-radius: 5px;
	color: $title-color;
	cursor: pointer;
	transition: all .3s ease-in-out 0s;
	position: relative;

	&:hover {
		background: lighten($btn-color, 10%);
		color: $primary-color;
	}

	span {
		display: flex;

		&.MuiCheckbox-colorPrimary {
			position: absolute;
			left: 0;
			top: 20%;
			flex: auto;
			background: $cbox-inactive;
			border-radius: 0 5px 5px 0;
			width: 5px;
			padding: 0;
			height: 60%;

			&.Mui-checked {
				background: $cbox-active;

				& ~ .MuiFormControlLabel-label{
					color: $primary-color;
				}
			}

			span {
				svg {
					display: none;
				}
			}
		}

		&.MuiFormControlLabel-label {
			display: flex;
			max-width: 200px;
			padding: 5px 15px;
			flex-wrap: wrap;
			font-size: 12px;
			font-family: $font-family;
			font-weight: $font-semibold;
		}
	}
}