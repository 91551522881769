// ******************************
// Import scss files you need!!!
// ******************************

@import "../base";
@import "../config";

// ******************************
// end of the imported scss files
// ******************************

.modal{
	display: flex;
	position: absolute;
	background-color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border: 1px solid #ebeff5;
	box-shadow: 0 16px 24px 0 transparentize($title-color, .8%), 0 32px 24px -16px transparentize($title-color, .8);
	outline: none;
	flex-direction: column;
	max-height: 80vh;
	min-width: 600px;
	pointer-events: all;
	max-width: 40em;
	width: 50vw;

	&-header{
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		padding: 5px 15px;
		display: flex;
		flex: 0 0 auto;
		justify-content: space-between;

		.h1{
			font-size: 18px;
			color: $title-color;
			font-family: $font-family;
		}
		.close{
			font-size: 16px;
			border-color: transparent;
			background-color: transparent;
			color: transparentize($title-color, .5);
			transition: all .3s ease-in-out;
			cursor: pointer;
			outline: none;

			&:hover{
				color: $title-color;
			}
		}
	}
	&-body{
		display: flex;
		flex-direction: column;
		flex: auto;
		padding: 5px 10px;
		margin: 15px 15px;

		.row{
			display: flex;
			margin: 10px 0;
		}
		.column{
			display: flex;
			flex-direction: column;
			flex: auto;
			margin: 0 15px;
		}
		.title{
			display: flex;
			width: 100%;
			flex: auto;
			font-family: $font-family;
			font-size: 12px;
			font-weight: $font-semibold;
			color: $title-color;
			margin-bottom: 5px;
			text-indent: 10px;
		}
	}
	&-footer{
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: solid 1px #c8d1e0;
		margin-top: 10px;
		padding: 20px 15px;

		.secondary-btn{
			font-family: $font-family;
			font-weight: $font-bold;
			color: transparentize($title-color, .2);
			transition: all .3s ease-in-out;

			&:hover{
				background-color: transparent;
				color: red;
			}
		}
		.primary-btn{
			font-family: $font-family;
			font-weight: $font-semibold;
			background-color: $primary-color;
			border-radius: 3px;
			color: $bg-color;
			transition: all .3s ease-in-out;

			&:hover{
				background-color: darken($primary-color, 20%);
			}
		}
	}
}


@include respond-below(sm) {
	.modal{
		top: 40%;
		max-height: 80vh;
		min-width: 400px;
		pointer-events: all;
		max-width: 40em;
		width: 80vw;

		.row{
			flex-direction: column;
			margin: 0;
		}
		.column{
			margin: 10px 0;
		}
	}
}