// ******************************
// Import scss files you need!!!
// ******************************

@import "../../styles/base";
@import "../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.App {
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		flex: auto;
		justify-content: space-between;
		padding: 10px 30px;
	}
	.ally-code {
		display: flex;
		flex-direction: column;

		.search {
			display: flex;
			flex: auto;
			align-items: center;

			.input{
				.MuiFilledInput-underline{
					background-color: transparent;

					&:hover{
						input{
							border-color: $secondary-color;
						}
					}
					&:after,
					&:hover:before{
						border-width: 0;
					}
					&:before{
						border-bottom-color: transparent;
					}
				}
				input{
					text-align: left;
					text-indent: 10px;
					min-width: 300px;
					font-size: 16px;
					font-weight: $font-semibold;
					font-family: $font-family;
					height: 36px;
					margin: 0;
					padding: 0 5px;
					border-radius: 3px 0 0 3px;
					border: solid 1px lighten($border-color, 5%);
					background-color: transparent;
				}
			}
			.refresh{
				width: 38px;
				height: 38px;
				background-color: lighten($border-color, 5%);
				color: $title-color;
				min-width: auto;
				margin-left: 0px;
				border-radius: 0 3px 3px 0;

				&:hover{
					cursor: pointer;
					color: $primary-color;
				}
			}
		}
		.subtitle {
			margin-top: 5px;
			margin-bottom: 10px;
			font-size: 11px;
			font-family: $font-family;
			font-weight: $font-semibold;
			color: transparentize($title-color, .4);

			span{
				color: transparentize($title-color, .1);
			}
		}
	}
	.body{
		display: flex;
		flex: auto;
		flex-direction: column;
		padding: 10px 30px;
		margin-top: 10px;

		.container{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			flex-direction: row;
			flex: auto;
			border: 1px solid $border-container;
			margin-bottom: 10px;

			&.gear{
				position: relative;
			}
			&.padd10{
				padding: 10px;
			}
			&.character-wrapper{
				position: relative;

				.clear-board{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					right: 0;
					top: 0;
					padding: 12px 14px;
					cursor: pointer;
					font-size: 18px;
					color: $secondary-color;
					border-color: $border-color;
					border-width: 0 0 1px 1px;
					border-style: solid;
					background-color: $bg-color;
					transition: all .3s ease-in-out;
					outline: none;

					&:hover{
						color: $primary-color;
						border-color: $primary-color;
					}
				}
			}

			.accordion{
				.accordion-header{
					display: flex;
					flex: auto;
					border-bottom: solid 1px $border-color;

					.title{
						margin-bottom: 0;
						font-size: 14px;
					}
					& > div{
						padding: 15px 10px;
						margin: 0;
					}
				}
			}
			.accordion,
			.table{
				display: flex;
				flex-direction: column;
				flex: auto;
				background-color: transparent;
				box-shadow: none;
				width: 100%;

				.rt-thead{
					&.-filters{
						background: $bg-color;
						border: none;

						.rt-th{
							//border: none;
							box-shadow: none;
						}
					}
					&.-header,
					.table-header{
						display: flex;
						flex: auto;

						.rt-th,
						.thead{
							padding: 5px;
							margin: 0;
							//border-right: none;
							outline: none;

						}
						.rt-th.-sort-asc,
						.rt-td.-sort-asc{
							box-shadow: inset 0 3px 0 0 transparentize($primary-color, .4);
						}
						.rt-th.-sort-desc,
						.rt-td.-sort-desc{
							box-shadow: inset 0 -3px 0 0 transparentize($primary-color, .4);
						}
						.rt-resizable-header-content,
						.title{
							display: flex;
							align-items: center;
							justify-content: center;
							margin-bottom: 0;
							color: $label-color;
							font-size: 12px;
							font-weight: $font-semibold;
							font-family: $font-family;
							white-space: pre-line;
							text-align: center;

							&:hover{
								cursor: pointer;
								color: $primary-color;
								transition: all .3s ease-in-out;
							}
						}
					}
				}
				.table-body{
					display: flex;
					flex-direction: column;
					flex: auto;
					margin-top: 0;
					padding: 10px 15px;
					border: none;

					.rt-tbody{
						display: flex;
						flex-direction: column;
						flex: auto;
						overflow: visible;

						.rt-tr-group{
							border-bottom: none;
						}
						.rt-tr{
							padding: 2px 5px;
							background-color: $row-color;
							margin-bottom: 3px;

							.rt-td{
								display: flex;
								align-items: center;
								justify-content: center;
								padding: 5px;

								&.flex-center{
									justify-content: center;
								}

								.gear,
								.relic{
									display: flex;
									align-items: center;
									flex: auto;
									overflow: hidden;

									&-img{
										height: 34px;
									}
									&-txt{
										@include overflow-ellipsis;
										text-indent: 5px;
										width: 100%;
										font-size: 12px;
										font-family: $font-family;
										font-weight: $font-regular;
									}
								}
								&-txt,
								.row-txt{
									font-family: $font-family;
									font-weight: $font-semibold;
									font-size: 12px;
									color: lighten($title-color, 5%);
									text-align: center;

									.hdb{
										font-weight: $font-regular;
										font-size: 10px;
									}
								}
								.quantity{
									border: 1px solid $border-color;
									border-radius: 5px;
									margin: 0 10px;

									&:hover{
										border-color: $secondary-color;
									}
									&:before,
									&:after{
										border-bottom-width: 0;
										margin: auto;
									}

									input{
										text-align: right;
										font-size: 16px;
										font-weight: $font-semibold;
										font-family: $font-family;
										color: $secondary-color;
										border-radius: 5px;
										padding: 5px 5px 6px;
									}
								}
							}
						}
					}
				}
				.locations{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					flex: auto;

					.item{
						font-size: 10px;
						font-family: $font-family;
						font-weight: $font-semibold;
						color: transparentize($title-color, .3);
						padding: 1px 3px;

						span{
							font-size: 11px;
							color: $title-color;
						}
					}
				}
				.token {
					display: flex;
					align-items: center;
					justify-content: center;
					@include overflow-ellipsis;
					font-weight: $font-semibold;
					font-family: $font-family;
					font-size: 12px;

					&-img{
						padding-left: 2px;
						height: 20px;
						width: auto;
					}
				}
			}
		}
	}
}

.title {
	font-family: $font-family;
	font-size: 12px;
	font-weight: $font-semibold;
	color: $title-color;
	margin-bottom: 10px;
}
.left-side {
	display: flex;
	flex: none;
	flex-direction: column;
	margin-top: 10px;
	margin-bottom: 20px;
}
.right-side {
	display: flex;
	align-items: flex-end;
	flex: auto;
	flex-direction: column;
	margin-top: 10px;
	margin-bottom: 20px;
	//overflow-x: auto;

	.container-presets{
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-end;
		flex-wrap: nowrap;
		padding: 0;
		margin: 0;

		& > label{
			margin: 0 5px;

			&:last-child{
				margin-right: 0;
			}
		}
		.carousel{
			width: 100%;

			.CarouselItem{
				display: flex;
				flex: auto;
				align-items: center;
				justify-content: flex-end;
				margin: 0 40px;

				& > div{
					display: flex;
					width: 100%;

					& > label{
						margin: 0 5px;

						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}
		.MuiFormControlLabel-label{
			padding: 0 5px 5px 5px;
			flex: auto;
			height: 100%;
		}
		.custom-cbox{
			background: #fefefe;
			border-radius: 5px;
			min-width: 110px;
			margin-left: 0 !important;

			&:hover {
				background: lighten($btn-color, 10%);
			}
		}
		.label{
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: auto;

			.preset-images{
				max-width: 80px;
				height: auto;
				max-height: 110px;
			}
			.txt{
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				flex: auto;
				width: 90%;
				text-transform: uppercase;
				padding: 0 10px;
			}
		}
	}
}
.tooltip{
	border-radius: 3px;
	display: inline-block;
	font-size: 13px;
	left: -999em;
	opacity: 0;
	padding: 8px 21px;
	position: fixed;
	pointer-events: none;
	transition: opacity 0.3s ease-out;
	top: -999em;
	visibility: hidden;
	z-index: 999;

	&:before{
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		bottom: -8px;
		left: 50%;
		margin-left: -10px;
	}
	&:before,
	&:after{
		content: "";
		width: 0;
		height: 0;
		position: absolute;
	}
	&.place-top:after {
		border-top-color: #222;
		border-top-style: solid;
		border-top-width: 6px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		bottom: -6px;
		left: 50%;
		margin-left: -8px;
	}
	&.show{
		margin-left: 0;
		opacity: .9;
		visibility: visible;
	}
}
.unit{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 2px 6px;
	padding: 4px;
	width: 100px;
	cursor: pointer;

	&.unit-add{
		img{
			width: 80px;
		}
		.unit-title{
			padding-top: 4px;
		}
	}
	&:hover{
		img{
			box-shadow: 0 0 8px 5px transparentize($title-color, .8);
		}
		.unit-force-dark{
			& ~ .unit-title{
				color: red;
			}
		}
		&.unit-add,
			.unit-force-light{
			& ~ .unit-title{
				color: $primary-color;
			}
		}
		&.unit-add{
			img{
				box-shadow: none;
			}
		}
	}

	&-avatar{
		display: flex;
		position: relative;
		flex-direction: column;

		&-portrait{

		}
		img{
			height: 100%;
			width: 70px;
			flex: 0 0 auto;
			max-width: 100%;
			border-radius: 100%;
		}
		&-gear {
			position: absolute;
			top: -2px;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center center;

		}
		&-gear13 {
			position: absolute;
			top: -7px;
			left: -10px;
			display: block;
			width: calc(100% * 1.3);
			height: 115%;
			background-size: 100%;
			background-position: center center;
		}
		&-relic {
			position: absolute;
			background-image: url('../../styles/img/relic-badge-atlas.png');
			background-size: 100%;
			bottom: -3%;
			right: -14%;
			width: 50%;
			height: 50%;
			color: white;
			font-size: 10px;
			line-height: 3.5;
			font-weight: $font-bold;
			font-family: $font-family;
			text-align: center;
			z-index: 4;
		}
		&-star {
			position: absolute;
			bottom: 50%;
			left: 50%;
			display: block;
			width: calc(100% / 5);
			height: calc(50% + 80% / 5 + 1px);
			background-image: url('../../styles/img/star.png');
			background-repeat: no-repeat;
			background-size: 100%;
			transform-origin: center bottom;

			&-1 {
				transform: translateX(-50%) rotateZ(-60deg);
			}

			&-2 {
				transform: translateX(-50%) rotateZ(-40deg);
			}

			&-3 {
				transform: translateX(-50%) rotateZ(-20deg);
			}

			&-4 {
				transform: translateX(-50%) rotateZ(0deg);
			}

			&-5 {
				transform: translateX(-50%) rotateZ(20deg);
			}

			&-6 {
				transform: translateX(-50%) rotateZ(40deg);
			}

			&-7 {
				transform: translateX(-50%) rotateZ(60deg);
			}
		}
		&.unit-force-dark {
			.unit-avatar-gear13 {
				background-position: 0 -100%;
			}
			.unit-avatar-relic {
				background-position: 0 -103%;
			}
		}
		&.unit-force-light {
			.unit-avatar-gear13 {
				background-position: 0 0;
			}
			.unit-avatar-relic {
				background-position: 0 0;
			}
		}
	}
	&-title{
		white-space: normal;
		width: 100%;
		max-width: 100px;
		font-family: $font-family;
		font-size: 11px;
		font-weight: $font-bold;
		line-height: 1.36;
		letter-spacing: -0.06px;
		text-align: center;
		color: $title-color;
		padding-top: 5px;
		margin-bottom: 10px;
	}
}
.select-unit{
	display: flex;
	flex: auto;
	width: 100%;
	font-size: 13px;

	& > div{
		width: 100%;
		cursor: pointer;
	}
}

@include respond-below(md){

	.App {
		.header {
			flex-direction: column;
			align-items: center;
			padding: 5px 15px;

			.left-side{
				align-items: center;
			}
			.right-side{
				align-items: center;

				.container-presets{
					flex-wrap: wrap;
					justify-content: center;
					flex: auto;

					.custom-cbox{
						margin: 10px;
						max-width: 120px;
					}
				}
			}
		}
		.body{
			padding: 5px 15px;
		}
	}
}

#relic-content{
	.table-body{
		.rt-thead{
			&.-filters{
				display: none;
			}
		}
	}
}
@include respond-above(sm){
	#gear-content,
	#relic-content{
		.table-body{
			.rt-thead{
				&.-header{
					.rt-tr {
						.rt-th {

							span {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
@include respond-below(sm){
	.home.collapsed{
		width: calc(100% - 4%);
	}
	#gear-content,
	#relic-content{
		.table-body{
			.rt-thead{
				min-width: initial !important;

				&.-header{
					padding: 5px;

					.rt-tr{
						display: flex;
						align-items: center;
						justify-content: space-between;

						.rt-th{
							display: none;
							align-items: center;
							justify-content: center;
							flex: auto !important;
							width: auto !important;
							max-width: 50% !important;

							&:before{
								content: 'Sort by ';
								display: inline-flex;
								align-items: center;
								justify-content: center;
								margin-bottom: 0;
								color: $label-color;
								font-size: 12px;
								font-weight: $font-semibold;
								font-family: $font-family;
								position: relative;
								padding-right: 3px;
							}
							&:nth-child(1),
							&:nth-child(2){
								display: flex;
								border-right: solid 1px $border-color;
							}
							&:nth-child(3){
								border-right: none;
								display: flex;
							}

							.resizer{
								display: none;
							}
						}
					}
				}
				&.-filters{
					.rt-tr{
						flex-direction: column;

						.rt-th{
							display: none;
							flex: auto !important;
							width: 100% !important;

							&:first-child{
								display: flex;
							}
						}
					}
				}
			}
			.rt-tbody{
				min-width: initial !important;

				.rt-tr-group{
					.rt-tr{
						flex-direction: column;
						flex: auto;
						margin-bottom: 5px;

						.rt-td{
							position: relative;
							padding-left: 40%;
							width: 60% !important;
							box-sizing: content-box;
							justify-content: flex-start;
							max-width: initial !important;
							padding-top: 3px;
							padding-bottom: 0;

							&:before{
								@include overflow-ellipsis;
								position: absolute;
								top: 8px;
								left: 0;
								width: 35%;
								white-space: nowrap;
								font-size: 12px;
								font-family: $font-family;
								box-sizing: border-box;
								text-align: right;
							}
							&.name:before { content: "Name:"; }
							&.required:before { content: "Required:"; }
							&.needed:before { content: "Needed:"; }
							&.owned:before { content: "Owned:"; }
							&.days-needed:before { content: "Days Remaining:";}
							&.energy:before { content: "Energy/Day:";}
							&.crystals:before { content: "Crystals:";}
							&.hideMobile {
								display: none
							}

							.row-txt,
							.token{
								height: 30px;
								align-items: center;
								justify-content: center;
								display: flex;
							}
							.quantity{
								width: 90% !important;
								margin: 0;

								input{
									text-align: left;
								}
							}
						}
					}
				}
			}
		}
	}
	#gear-content{
		.table-body{
			.rt-tbody{
				.rt-tr-group{
					.rt-tr{
						.rt-td{
							&.name:before { content: "Name:"; top: 10px;}
							&.required:before { content: "Required:"; }
							&.needed:before { content: "Needed:"; }
							&.owned:before { content: "Owned:"; }
							&.farm-light-side:before { content: "Light Side Battles:";}
							&.farm-dark-side:before { content: "Dark Side Battles:";}
							&.farm-fleet:before { content: "Fleet Battles:"; }
							&.days-needed:before { content: "Days Remaining:";}
							&.energy:before { content: "Normal Energy/Day spent:"; }
							&.fleet-energy:before { content: "Fleet Energy/Day spent:"; }
							&.tokens {
								&:nth-last-child(6):before {
									content: "Crystals:";
								}
								&:nth-last-child(5):before {
									content: "Championship Token:";
								}
								&:nth-last-child(4):before {
									content: "Guild Token:";
								}
								&:nth-last-child(3):before {
									content: "Guild Event Token I:";
								}
								&:nth-last-child(2):before {
									content: "Guild Event Token II:";
								}
								&:nth-last-child(1):before {
									content: "Shard Store Token:";
								}
							}

							.locations{
								justify-content: flex-start;
								//min-height: 20px;
								margin-top: 5px;
							}
						}
					}
				}
			}
		}
	}
}

@include respond-below(xs){
	.App{
		.header,
		.body{
			padding: 5px 0;

			.container{
				.accordion{
					.table-body{
						padding: 0;
					}
				}
			}
		}
	}
}
